<template>
	<el-dialog
	    width="1300px"
		:destroy-on-close="true"
	  >
		<div class="a">
			<div class="a-title">收款记录</div>
			<div class="p">
				<lidaPage 
					:pagination="false"
					:query="purchaseCollectionList"
					:queryInfo="{documentId:pid}"
					:columns="columns"
					ref="page"
					height="500px">
					<template #undoFlag="scope">
						<div>{{scope.row.undoFlag === '1' ? '是' : '否'}}</div>
					</template>
					<template #operation>
						<lida-table-column label="操作" width="60" fixed="right">
							<template #default="scope">
								<el-button @click="nullify(scope.row)" type="text" v-if="scope.row.undoFlag !== '1'">作废</el-button>
							</template>
						</lida-table-column>
					</template>
				</lidaPage>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions , mapState } from "vuex";
	import { ElMessage , ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				value:'',  //搜索值
				data:[],  //列表数据
				columns:[
					{label:'买方名称',prop:'buyerName'},
					{label:'买方企业名称',prop:'buyerEnterpriseName'},
					{label:'卖方名称',prop:'sellerName'},
					{label:'卖方企业名称',prop:'sellerEnterpriseName'},
					{label:'收款金额',prop:'paidAmount'},
					{label:'收款时间',prop:'collectionTime'},
					{label:'是否作废',prop:'undoFlag'},
					{label:'备注',prop:'remarks'},
				]
			}
		},
		props:{
			pid:{
				type:String,
				default:null,
			}
		},
		methods:{
			...mapActions('purchaseMudule',[
				'skuPage',
			]),
			...mapActions('mysaleMudule',[
				'purchaseCollectionList',  //获取列表
				'purchaseCollectionUndo',  //支付记录作废
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			nullify(row){ //作废
				ElMessageBox.confirm(
					"是否要作废当前支付记录?",
					"提示", {
						confirmButtonText: "确认",
						cancelButtonText: "取消",
						type: "warning"
					}
				)
				.then(() => {
					this.purchaseCollectionUndo({id:row.id}).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							ElMessage.success("操作成功");
							this.$refs.page.refresh();  //刷新页面
						}
					})
				})
				.catch(() => {});
			}
		},
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px;
		.a-title{
			padding-bottom: 20px;
			font-weight: bold;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
	.p{
		
	}
	
</style>
