<template>
  <div class="o">
    <div class="o-title">
      供方: {{ orderInfo?.sellerName }}/{{ orderInfo?.sellerEnterpriseName }}/{{
        orderInfo?.sellerPhone
      }}
    </div>
    <div class="o-title">
      需方: {{ orderInfo?.buyerName }}/{{ orderInfo?.buyerEnterpriseName }}/{{
        orderInfo?.buyerPhone
      }}
    </div>
    <div class="line"></div>
    <div class="o-NO bg">
      <span>订单号: </span>{{ orderInfo.documentNumber }}
    </div>
    <div class="o-status bg">
      <div class="o-item">
        <div class="o-s-title">付<br />款<br />信<br />息</div>
        <div class="o-s-content">
          <div class="o-s-gbg">
            <p class="iconfont icon-jine"></p>
            <span>{{ orderInfo.isPayText }}</span>
          </div>
          <div class="o-s-text">
            <span
              >已付款: <i>￥{{ orderInfo.paidAmount || 0 }}</i></span
            >
          </div>
          <div class="o-s-text">
            <span
              >全款: <i>￥{{ orderInfo.totalAmount }}</i></span
            >
          </div>
        </div>
      </div>
      <div class="o-item">
        <div class="o-s-title">发<br />货<br />状<br />态</div>
        <div class="o-s-content o-s-center">
          <p class="iconfont icon-fahuo"></p>
          <span>{{ orderInfo.shipStatusText }}</span>
        </div>
      </div>
      <div class="o-item">
        <div class="o-s-title">合<br />同<br />信<br />息</div>
        <div class="o-s-content o-s-contract">
          <div class="left" style="flex: 1;">
            <div class="o-i-title">卖方合同</div>

            <div
              style=" display: flex;  justify-content: center;  padding-top: 15px; overflow-y: scroll;"
            >
              <el-button
                size="mini"
                style="width: 70px; height: 25px; margin: 15% 5px 0px 5px; text-align: center; padding: 3px;"
                type="danger"
                @click="previewContract"
                plain
                >合同预览</el-button
              >
              <div class="">
                <upload-file
                  v-model="maiIds"
                  isType="add"
                  list-type="picture-card"
                  @usuccess="contractSuccess"
                  @delete="contractSuccess"
                  :disabled="maiUL"
                  :limit="3"
                  :list="orderInfo.contractFiles"
                />
              </div>
            </div>
          </div>
          <div class="right" style="flex: 1;">
            <div class="o-i-title">买方合同</div>
            <div
              class=""
              style=" display: flex;  justify-content: center;  padding-top: 15px; overflow-y: scroll;"
            >
              <!-- v-if="orderInfo.contractStatus !== '0'" -->
              <upload-file
                v-model="ids"
                isType="add"
                list-type="picture-card"
                @usuccess="upSuccess"
                @delete="upSuccess"
                :disabled="buyUL"
                :limit="3"
                :list="orderInfo.backContractFiles"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="o-btn bg">
      <el-button size="mini" @click="goRecord">{{
        isPurchase ? "付款记录" : "收款记录"
      }}</el-button>
      <el-button size="mini" @click="collection">收付款</el-button>
      <el-button
        size="mini"
        @click="showApplyBilling = true"
        :disabled="orderInfo.invoiceStatus === '2'"
        v-if="orderInfo.invoiceStatus !== '1' && isBuyer"
        >{{
          orderInfo.invoiceStatus === "2" ? "开票申请中" : "申请开票"
        }}</el-button
      >
      <el-button size="mini" @click="sendGoods" v-if="!isBuyer">发货</el-button>
    </div>
    <template v-if="orderInfo?.purchaseShipResultVOList?.length">
      <div class="line"></div>
      <div class="o-package">
        <div class="o-p-title">
          <div class="o-p-subtitle">发货单</div>
          <div class="o-p-item">
            <span>发货单号: </span
            >{{ orderInfo.purchaseShipResultVOList[activeSg].courierNumber }}
          </div>
          <div class="o-p-item">
            <span>物流公司: </span
            >{{
              getType(
                "courierCompany",
                orderInfo.purchaseShipResultVOList[activeSg].courierCompany
              )
            }}
          </div>
          <div class="o-p-item">
            <span>备注: </span
            >{{ orderInfo.purchaseShipResultVOList[activeSg].remarks }}
          </div>
          <div class="o-p-item">
            <el-button
              type="text"
              v-if="
                orderInfo.purchaseShipResultVOList[activeSg].undoFlag == '0' &&
                  orderInfo.sellerId == userId
              "
              @click="removeGoods(orderInfo.purchaseShipResultVOList[activeSg])"
              style="color: #D84146;cursor: pointer;"
              >作废</el-button
            >
            <el-button
              type="text"
              v-if="
                orderInfo.purchaseShipResultVOList[activeSg].undoFlag == '1'
              "
              style="color: #D84146;cursor: pointer;"
              >已作废</el-button
            >
          </div>
        </div>
        <div class="o-p-content">
          <el-tabs type="border-card" v-model="activeSg">
            <el-tab-pane
              :label="'包裹' + (index + 1)"
              v-for="(item, index) in orderInfo.purchaseShipResultVOList"
              :key="index + 1"
            />
          </el-tabs>
          <div class="o-card">
            <div class="left">
              <lidaPage
                :height="
                  orderInfo.purchaseShipResultVOList[activeSg]?.isReceipt ===
                    '0' && isBuyer
                    ? '350px'
                    : '400px'
                "
                :ref="page"
                :columns="columns"
                :showRefreshBtn="false"
                :showSetupBtn="false"
                :data="
                  orderInfo.purchaseShipResultVOList[activeSg]
                    .shipItemResultList
                "
                :pagination="false"
              >
                <template #goods="scope">
                  <div class="g-info" style="padding: 10px 0;">
                    <div class="g-icon">
                      <img :src="scope.row.src" />
                    </div>
                    <div class="g-right">
                      <div class="g-r-title">
                        {{ scope.row.productName }}
                        <div class="label" :title="scope.row.brandName">
                          {{ scope.row.brandName }}
                        </div>
                      </div>
                      <div class="g-r-data">
                        {{ scope.row.barCode || "无" }}
                        <span>|</span>
                        {{ scope.row.seriesName || "无" }}
                        <span>|</span>
                        {{ scope.row.skuNumber || "无" }}
                      </div>
                    </div>
                  </div>
                </template>
              </lidaPage>
              <div
                style="padding: 10px 0;display: flex;justify-content: flex-end;"
                v-if="
                  orderInfo.purchaseShipResultVOList[activeSg]?.isReceipt ===
                    '0' && isBuyer
                "
              >
                <el-button
                  type="success"
                  size="mini"
                  @click="
                    confirmReceiving(
                      orderInfo.purchaseShipResultVOList[activeSg]
                    )
                  "
                  >确认收货</el-button
                >
              </div>
            </div>
            <div class="right">
              <logistics height="390px" :data="wuliu" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="line"></div>
    <div class="o-invoice">
      <div class="o-p-content" style="display: flex;">
        <div class="o-i">
          <div class="o-i-title">收货信息</div>
          <div class="o-i-content">
            <lidaForm
              :labelWidth="100"
              :span="1"
              :formValues="formValues1"
              ref="from1"
              :formColumns="formColumns1"
              v-if="e1IsEdit"
            >
              <div class="e-btn">
                <el-button size="mini" @click="e1IsEdit = false"
                  >取消</el-button
                >
                <el-button @click="confirmBtn('from1')" size="mini"
                  >确认</el-button
                >
              </div>
            </lidaForm>
            <template v-else>
              <div class="o-NO">
                <span>收货人：</span>{{ formValues1.consigneeName }}
              </div>
              <div class="o-NO">
                <span>地址：</span
                >{{
                  formValues1.area.join("-") + " " + formValues1.receiveAddress
                }}
              </div>
              <div class="o-NO">
                <span>电话：</span>{{ formValues1.consigneePhone }}
              </div>
              <div class="el-icon-edit" @click="e1IsEdit = true"></div>
            </template>
          </div>
        </div>
        <div class="o-i">
          <div class="o-i-title">收票地址</div>
          <div class="o-i-content" v-if="orderInfo.invoiceStatus === '1'">
            <lidaForm
              :labelWidth="100"
              :span="1"
              :formValues="formValues2"
              ref="from2"
              :formColumns="formColumns1"
              v-if="e2IsEdit"
            >
              <div class="e-btn">
                <el-button size="mini" @click="e2IsEdit = false"
                  >取消</el-button
                >
                <el-button @click="confirmBtn('from2')" size="mini"
                  >确认</el-button
                >
              </div>
            </lidaForm>
            <template v-else>
              <div class="o-NO">
                <span>收货人：</span>{{ formValues2.consigneeName }}
              </div>
              <div class="o-NO">
                <span>地址：</span
                >{{
                  formValues2.area.join("-") + " " + formValues2.receiveAddress
                }}
              </div>
              <div class="o-NO">
                <span>电话：</span>{{ formValues2.consigneePhone }}
              </div>
              <div class="el-icon-edit" @click="e2IsEdit = true"></div>
            </template>
          </div>
        </div>
        <div class="o-i">
          <div class="o-i-title">发票信息</div>
          <div class="o-i-content" v-if="orderInfo.invoiceStatus === '1'">
            <lidaForm
              :labelWidth="100"
              :span="1"
              :formValues="formValues2"
              ref="from3"
              :formColumns="formColumns3"
              v-if="e3IsEdit"
            >
              <div class="e-btn">
                <el-button size="mini" @click="e3IsEdit = false"
                  >取消</el-button
                >
                <el-button @click="confirmBtn('from3')" size="mini"
                  >确认</el-button
                >
              </div>
            </lidaForm>
            <template v-else>
              <div class="o-NO">
                <span>公司名称：</span>{{ formValues2.invoiceHead }}
              </div>
              <div class="o-NO">
                <span>税务登记证号：</span>{{ formValues2.taxpayerCode }}
              </div>
              <div class="o-NO">
                <span>公司开户行名称：</span>{{ formValues2.bankName }}
              </div>
              <div class="o-NO">
                <span>公司开户行账号：</span>{{ formValues2.bankAccount }}
              </div>
              <div class="o-NO">
                <span>注册场所地址：</span>{{ formValues2.address }}
              </div>
              <div class="o-NO">
                <span>注册固定电话：</span>{{ formValues2.invoicePhone }}
              </div>
              <div class="o-NO">
                <span>发票类型：</span>{{ formValues2.invoiceType }}
              </div>
              <div class="el-icon-edit" @click="e3IsEdit = true"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-row>
      <el-col :span="6">
        <div class="o-NO">{{ orderInfo.documentDate }}</div>
      </el-col>
      <el-col :span="6">
        <div class="o-NO">
          <span>订单号:</span>{{ orderInfo.documentNumber }}
        </div>
      </el-col>
      <el-col :span="6">
        <div class="o-NO">
          <span>采购商家:</span
          >{{ orderInfo.buyerName + "(" + orderInfo.buyerEnterpriseName + ")" }}
        </div>
      </el-col>
    </el-row>
    <lidaPage
      height="400px"
      :data="orderInfo.documentItemResultVOList"
      :columns="columns1"
      :showRefreshBtn="false"
      :showSetupBtn="false"
      :pagination="false"
    >
      <template #goods="scope">
        <div class="g-info" style="padding: 10px 0;">
          <div class="g-icon">
            <img :src="scope.row.src" />
          </div>
          <div class="g-right">
            <div class="g-r-title">
              {{ scope.row.productName }}
              <div class="label" :title="scope.row.brandName">
                {{ scope.row.brandName }}
              </div>
            </div>
            <div class="g-r-data">
              {{ scope.row.barCode || "无" }}
              <span>|</span>
              {{ scope.row.seriesName || "无" }}
              <span>|</span>
              {{ scope.row.skuNumber || "无" }}
            </div>
          </div>
        </div>
      </template>
    </lidaPage>
    <div class="line"></div>
    <div class="o-info bg">
      <el-row
        ><el-col
          >货品种类：
          {{ orderInfo?.documentItemResultVOList?.length || 0 }}种</el-col
        ></el-row
      >
      <el-row
        ><el-col>总计数量： {{ totalNum }}件</el-col></el-row
      >
      <!-- <el-row
        ><el-col
          >订单总金额：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >￥{{ orderInfo.totalAmount }}</span
          ></el-col
        ></el-row
      > -->
      <!-- <el-row>
        <el-col
          >服务费（元）：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >￥{{ orderInfo.totalAmount }}</span
          ></el-col
        >
      </el-row>
       <el-row>
        <el-col
          >未税金额（元)：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >￥{{ orderInfo.totalAmount }}</span
          ></el-col
        >
      </el-row>
       <el-row>
        <el-col
          >税额：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >￥{{ orderInfo.totalAmount }}</span
          ></el-col
        >
      </el-row> -->
      <el-row>
        <el-col
          >合计金额（元）：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >￥{{ orderInfo.totalAmount }}</span
          ></el-col
        >
      </el-row>
      <el-row>
        <el-col
          >合计金额（大写）：
          <span style="color: #D84146;font-size: 16px;font-weight: bold;"
            >人民币{{ total }}</span
          ></el-col
        >
      </el-row>
    </div>
  </div>

  <!-- 待收款弹框 -->
  <collectionDialog
    v-model="collectionShow"
    :orderInfo="orderInfo"
    :oid="$route.query.id"
    :files="orderInfo.payFiles || []"
    @success="sendSuccess"
    @upSuccess="confirmPayment"
  />

  <!-- 收款记录弹框 -->
  <collectionRecord v-model="showCollectionRecord" :pid="$route.query.id" />

  <!-- 发货弹框 -->
  <send-googd-dialog
    v-model="sendGoogdShow"
    :orderInfo="orderInfo"
    :oid="$route.query.id"
    @success="sendSuccess"
  />

  <!-- 申请开票弹框 -->
  <applyBilling
    v-model="showApplyBilling"
    @success="sendSuccess"
    :orderInfo="orderInfo"
    :oid="$route.query.id"
  />
</template>

<script>
import logistics from "@/components/publicComponent/logistics.vue"; //物流
import { mapActions, mapState } from "vuex";
import collectionDialog from "../components/dialog/collection.vue"; //待收款弹框
import { ElMessage, ElMessageBox } from "element-plus";
import collectionRecord from "../components/dialog/collectionRecord.vue"; //收款记录
import sendGoogdDialog from "../components/dialog/sendGoogd.vue"; //发货弹框
import lidaForm from "@/components/publicComponent/lidaForm.vue";
import applyBilling from "../components/dialog/applyBilling.vue"; //申请开票
import uploadFile from "@/components/publicComponent/uploadFile.vue"; //上传文件组件
import { convertCurrency } from "../mixins/handleAmount";
export default {
  data() {
    return {
      ids: [], //回传合同的ids
      userId: "",
      columns: [
        //配置设置
        { label: "产品信息", prop: "goods" },
        { label: "订货号", prop: "articleNo" },
        { label: "采购数量", prop: "purchaseQty" },
        { label: "发货数量", prop: "shipQty" },
      ],
      columns1: [
        { label: "产品信息", prop: "goods" },
        { label: "型号/订货号", prop: "articleNo" },
        { label: "单价", prop: "purchasePrice" },
        { label: "数量", prop: "purchaseQty" },
        { label: "是否过保", prop: "isExpire" },
        { label: "总金额", prop: "totalAmount" },
      ],
      formColumns1: [
        {
          label: "收货人",
          prop: "consigneeName",
          rules: [{ message: "请输入收货人", required: true }],
        },
        {
          label: "地址",
          prop: "area",
          type: "area",
          rules: [{ message: "请选择地址", required: true }],
        },
        {
          label: "电话",
          prop: "consigneePhone",
          rules: [{ message: "请输入电话", required: true }],
        },
        {
          label: "详细地址",
          prop: "receiveAddress",
          rules: [{ message: "请输入详细地址", required: true }],
        },
      ],
      formValues1: {
        consigneeName: null,
        consigneePhone: null,
        area: [],
        receiveAddress: null,
      },
      formValues2: {
        consigneeName: null,
        consigneePhone: null,
        area: [],
        receiveAddress: null,
      },
      formColumns3: [
        {
          label: "公司名称",
          prop: "invoiceHead",
          rules: [{ message: "请输入公司名称", required: true }],
        },
        {
          label: "税务登记证号",
          prop: "taxpayerCode",
          rules: [{ message: "请输入税务登记证号", required: true }],
        },
        {
          label: "公司开户行名称",
          prop: "bankName",
          rules: [{ message: "请输入公司开户行名称", required: true }],
        },
        {
          label: "公司开户行账号",
          prop: "bankAccount",
          rules: [{ message: "请输入公司开户行账号", required: true }],
        },
        {
          label: "注册场所地址",
          prop: "address",
          rules: [{ message: "请输入注册场所地址", required: true }],
        },
        {
          label: "注册固定电话",
          prop: "invoicePhone",
          rules: [{ message: "请输入注册固定电话", required: true }],
        },
        {
          label: "发票类型",
          prop: "invoiceType",
          rules: [{ message: "请输入发票类型", required: true }],
        },
      ],
      e1IsEdit: false,
      e2IsEdit: false,
      e3IsEdit: false,
      wuliu: [], //物流信息
      orderInfo: {}, //订单信息
      info: {}, //询价单信息
      collectionShow: false,
      payVoucherShow: false,
      showCollectionRecord: false,
      sendGoogdShow: false,
      activeSg: 0,
      showApplyBilling: false,
      total: "",
    };
  },
  components: {
    logistics,
    collectionDialog,
    collectionRecord,
    sendGoogdDialog,
    lidaForm,
    applyBilling,
    uploadFile,
  },
  computed: {
    ...mapState("purchaseMudule", [
      "paymentTypes",
      "protects",
      "moneys",
      "documentState",
      "projectState",
      "isPay",
      "shipStatus",
      "courierCompany",
    ]),
    ...mapState(["userInfo"]),
    isBuyer() {
      //判断是不是买家 采购单
      return this.userInfo.userId === this.orderInfo.buyerId;
    },
    maiUL() {
      //禁用卖方合同

      return (
        this.userInfo.userId === this.orderInfo.buyerId &&
        this.orderInfo.backContractFiles &&
          this.orderInfo.backContractFiles !== "[]"
      );
    },
    buyUL() {
      //禁用买方合同
      console.log(this.userInfo.userId, this.orderInfo.buyerId);
      return (
        this.userInfo.userId !== this.orderInfo.buyerId ||
        this.orderInfo.isPay !== "0" ||
        this.orderInfo.shipStatus !== "NIL"
      );
    },
    isPurchase() {//是否是采购单

      return this.$route.fullPath?.includes("type=_purchase");
    },
    totalNum() {
      let num = 0;
      this.orderInfo &&
        this.orderInfo.documentItemResultVOList &&
        this.orderInfo.documentItemResultVOList.map((item) => {
          num += item.purchaseQty;
        });
      return num;
    },
  },
  methods: {
    ...mapActions("purchaseMudule", [
      // "inquiryInfo", //获取询价详情
      "purchaseUpdatePayment", //确认付款
      "purchaseUpdateBackContractFile", //回传合同
    ]),

    ...mapActions("mysaleMudule", [
      "inquirySellerInfo",
      "purchaseDetails", //获取订单详情
      "purchaseGetExpress", //查看当前物流
      "userConsigneeDefault", //获取买家默认收货地址
      "purchaseUpdateAddress", //修改采购单收货地址
      "invoiceUpdateAddress", //修改采购单发票地址
      "invoiceInfo", //获取订单发票详情
      "purchaseReceipt", //确认收货
      "purchaseShipUndo",
      "purchaseUpdateContractFile",
    ]),
    getOrderInfo() {
      //获取订单详情
      this.purchaseDetails(this.$route.query.id).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          data.documentState = this.documentState[data.documentStatus];
          data.contractFiles = JSON.parse(data.contractFiles || "[]");
          data.backContractFiles = JSON.parse(data.backContractFiles || "[]");
          data.payFiles = JSON.parse(data.payFiles || "[]"); //支付凭证
          data.isPayText = this.getType("isPay", data.isPay);
          data.shipStatusText = this.getType("shipStatus", data.shipStatus);
          this.orderInfo = data;
          this.total = convertCurrency(this.orderInfo.totalAmount);
          if (
            data.purchaseShipResultVOList &&
            data.purchaseShipResultVOList.length
          ) {
            this.getLogistics(data.purchaseShipResultVOList[0]);
          }
          if (data.consigneeName) {
            //判断采购单是否有收货人
            this.formValues1 = {
              consigneeName: data.consigneeName,
              consigneePhone: data.consigneePhone,
              area: [data.province, data.city, data.district],
              receiveAddress: data.receiveAddress,
            };
          } else {
            this.getDefaultAddress(data.buyerId);
          }
        }
      });
    },
    getDetails() {
      //获取询价单详情
      this.inquirySellerInfo({
        inquiryId: this.$route.query.inquiryId,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          //data.inquiryRequestVO.documentTime = moment(data.inquiryRequestVO.documentTime).format('YYYY.MM.DD hh:mm:ss');
          data.inquiryRequestVO.priceUnit = this.getType(
            "moneys",
            data.inquiryRequestVO.priceUnit
          );
          data.inquiryRequestVO.projectFile = JSON.parse(
            data.inquiryRequestVO.projectFile || "[]"
          );
          data.inquiryRequestVO.file = JSON.parse(
            data.inquiryRequestVO.file || "[]"
          );
          data.inquiryRequestVO.documentText = this.documentState[
            data.inquiryRequestVO.documentState
          ];
          data.inquiryRequestVO.paymentType = this.getType(
            "paymentTypes",
            data.inquiryRequestVO.paymentType
          );
          data.inquiryRequestVO.projectState = data.inquiryRequestVO
            .projectState
            ? this.getType("projectState", data.inquiryRequestVO.projectState)
            : "";
          this.info = data; //商品列表
        }
      });
    },
    getType(attribute, type) {
      return type
        ? attribute === "paymentTypes" &&
          !this[attribute].filter((v) => v.type === type).length
          ? this[attribute][0].title
          : this[attribute].filter((v) => v.type === type).length
          ? this[attribute].filter((v) => v.type === type)[0].title
          : ""
        : "";
    },
    previewContract() {
      //跳转到合同页面
      this.$router.push({
        path: "/contract",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    collection() {
      //收款
      this.collectionShow = true;
    },
    payment() {
      //支付
      this.payVoucherShow = true;
    },
    sendSuccess() {
      //刷新页面数据
      this.activeSg = 0;
      this.getDetails();
      this.getOrderInfo();
    },
    confirmPayment(ids) {
      //确认付款
      this.purchaseUpdatePayment({
        documentId: this.$route.query.id,
        payFiles: ids,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "提交成功",
          });
        }
      });
    },
    goRecord() {
      //查询收款记录
      this.showCollectionRecord = true;
    },
    sendGoods() {
      //发货
      this.sendGoogdShow = true;
    },
    removeGoods(v) {
      console.log(v);
      //作废发货单
      this.purchaseShipUndo({ id: v.id }).then((res) => {
        // console.log(res);
        if (res && res.status == 200) {
          // this.sendSuccess()
          this.getOrderInfo();
          this.$message({
            message: "作废成功",
            type: "success",
          });
        } else {
          this.$message.error("作废失败");
        }
      });
    },
    getLogistics(row) {
      //获取物流信息
      this.purchaseGetExpress({
        courierNumber: row.courierNumber || "",
        courierCompany: row.courierCompany || "",
        courierPhone:
          row.courierCompany === "shunfeng" ? row.courierPhone : undefined,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          if (data.status === "200") {
            this.wuliu = data.data;
          } else {
            ElMessage({
              type: "error",
              message: data.message,
            });
          }
        }
      });
    },
    getDefaultAddress(id) {
      //获取买家默认地址（如果采购单没有收货地址）
      this.userConsigneeDefault(id).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          if (data && data.id) {
            this.formValues1 = {
              consigneeName: data.consigneeName,
              consigneePhone: data.consigneePhone,
              area: [data.province, data.city, data.district],
              receiveAddress: data.address,
            };
          }
        }
      });
    },
    getInvoiceInfo() {
      //获取订单发票详情
      this.invoiceInfo(this.$route.query.id).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          if (data && data.id) {
            this.formValues2 = {
              ...data,
              area: [data.province, data.city, data.district],
            };
          }
        }
      });
    },
    confirmBtn(type) {
      this.$refs[type].$refs.form.validate((valid, values) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(values));
          let fn;
          if (data.area && data.area.length) {
            data.province = data.area[0] || null;
            data.city = data.area[1] || null;
            data.district = data.area[2] || null;
            delete data.area;
          }
          data = {
            ...(type === "from1" ? this.orderInfo : this.formValues2),
            ...data,
          };
          fn =
            type === "from1"
              ? this.purchaseUpdateAddress
              : this.invoiceUpdateAddress;
          fn(data).then((res) => {
            let { data, code } = res.data;
            if (code === "0") {
              ElMessage.success("修改成功");
              this.e1IsEdit = false;
              this.e2IsEdit = false;
              this.e3IsEdit = false;
            }
          });
        }
      });
    },

    upSuccess() {
      //上传成功的回调
      this.$nextTick(() => {
        this.purchaseUpdateBackContractFile({
          //回传合同
          documentId: this.$route.query.id,
          backContractFileIds: this.ids,
        }).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            ElMessage.success("操作成功");
          }
        });
      });
    },
    contractSuccess(obj) {
      //上传合同成功的回调
      this.purchaseUpdateContractFile({
        contractFileIds: this.maiIds,
        documentId: this.$route.query.id,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "上传成功",
          });
          //刷新详情数据
        }
      });
    },
    confirmReceiving(row) {
      //确认收货
      ElMessageBox.confirm("是否已收到货?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.purchaseReceipt({
            documentId: this.$route.query.id,
            receiptRemarks: "",
            shipId: row.id,
          }).then((res) => {
            let { code, data } = res.data;
            if (code === "0") {
              ElMessage.success("操作成功");
              this.getOrderInfo(); //刷新订单状态
            }
          });
        })
        .catch(() => {});
    },
  },
  created() {
    this.getDetails();
    this.getOrderInfo();
    this.getInvoiceInfo();
    this.userId = JSON.parse(localStorage.getItem("userInfo")).userId;
  },
  watch: {
    activeSg(v) {
      this.wuliu = [];
      this.getLogistics(this.orderInfo.purchaseShipResultVOList[v]);
    },
  },
};
</script>

<style scoped lang="less">
@import "../style/xinOrderDetails.less";
</style>
