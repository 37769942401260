<template>
	<div class="a-content" :style="{height:height}">
		<div class="a-item" v-for="(item,index) in data" :key="index">
			<div class="a-item-line"></div>
			<div class="a-item-m">
				<div class="a-item-time">{{item.time}}</div>
				<div class="a-item-text">{{item.context}}</div>
			</div>
		</div>
		<div class="a-item" v-if="data.length"></div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:{
			data:{type:Array,default:() => []},
			height:{type:[String,Number],default:'500px'},
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="less">
	.a-content{
		box-sizing: border-box;
		overflow-y: auto;
		padding: 10px 0;
	}
	.a-item{
		display: flex;
		position: relative;
		box-sizing: border-box;
		padding-left: 40px;
		.a-item-m{
			box-sizing: border-box;
			padding: 10px 0;
			.a-item-time{
				font-size: 10px;
				color: #999999;
			}
			.a-item-text{
				box-sizing: border-box;
				width: 500px;
				padding: 10px 20px;
			}
		}
	}
	.a-item::after,
	.a-item::before{
		border-radius: 50%;
		position: absolute;
		content: "";
		left: 20px;
		transform: translateX(-50%);
	}
	.a-item::after{
		width: 4px;
		height: 4px;
		top: 4px;
		z-index: 100;
		background: #FFA500;
	}
	.a-item::before{
		width: 8px;
		height: 8px;
		border: 2px solid #FFA500;;
	}
	.a-item-line{
		position: absolute;
		left: 20px;
		width: 2px;
		transform: translateX(-50%);
		top: 12px;
		bottom: 0px;
		background: #FFA500;
	}
</style>
